/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Nav from "../../components/UI/Navbar";
import Sidebar from "../../components/UI/Sidebar";
import {
  Box,
  Flex,
  Input,
  Progress,
  Radio,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Heading, Fade, useDisclosure } from "@chakra-ui/react";
import Dashboard from "../../services/dashboard";
import { getValues } from "../../reducers/loginReducer";
import { useSelector } from "react-redux";
import Top from "./Top";
import {
  PieChart as PieChart02,
  pieArcLabelClasses,
} from "@mui/x-charts/PieChart";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Modal, RadioGroup } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Result } from "react-leaf-polls";
import "react-leaf-polls/dist/index.css";
import "react-tabs/style/react-tabs.css";
import { Columns } from "../../utils/Top";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/HOC's/DataTableTop";
import TextComponent from "../../components/Form/Text";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

export default function Index() {
  const { id, rol } = useSelector(getValues);

  const { isOpen, onOpen } = useDisclosure();
  const [data, setdata] = useState<any>({
    clientes: 0,
    venta_anual: 0,
    satisfaccion: {
      insatisfecho: 0,
      neutral: 0,
      satisfechos: 0,
    },
    venta_mes: 0,
    venta_mes_anterior: 0,
  });
  const [title_01, setTitle_01] = useState("");
  const [title_03, setTitle_03] = useState("");
  const [modal, setModal] = useState(false);
  const [title_02, setTitle_02] = useState("");
  const [newData, setNewData] = useState<any>({
    implementos: 0,
    productos: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [data02, setdata02] = useState<any>([]);
  const [data03, setdata03] = useState<any>([]);
  const [data04, setdata04] = useState<any>([]);
  const [data05, setdata05] = useState<any>([]);
  const [data06, setdata06] = useState<any>([]);
  const [data07, setdata07] = useState<Result[]>([]);
  const [data08, setdata08] = useState<Result[]>([]);
  const [columns3, setcolumns3] = useState<any>([]);
  const [data3, setdata3] = useState<any>([]);

  useEffect(() => {
    if (id) {
      onOpen();
      Dashboard.DashboardGet(id).then((res) => {
        setdata(res);
        setdata04([
          { value: res?.clientes ?? 0, label: "Clientes", color: "#4F71BE" },
          {
            value: res?.posibles_clientes ?? 0,
            label: "Posibles clientes",
            color: "#DE8344",
          },
        ]);
      });
      Dashboard.ticketFuentesGet().then((res) => {
        const tickets = res.tickets;
        const data = Object.keys(tickets).map((key) => {
          return {
            label: key,
            value: tickets[key],
          };
        });
        setdata02(data);
      });
      Dashboard.ticketSeguimientoGet().then((res) => {
        const tickets = res.tickets;
        const data = Object.keys(tickets).map((key) => {
          return {
            label: key,
            value: tickets[key].total,
            datos: Object.keys(tickets[key].prioridad).map((key2) => {
              return {
                label: key2,
                value: tickets[key].prioridad[key2],
              };
            }),
          };
        });
        setdata03(data);
      });
      Dashboard.productosVendidosGet().then((res) => {
        setNewData((prev: any) => {
          return {
            ...prev,
            implementos: res.implementos,
            productos: res.productos,
          };
        });
      });

      Dashboard.satisfaccionGet().then((res) => {
        const satisfaccion = res.satisfaccion;
        setdata07([
          {
            id: -1,
            text: "Sin contestar encuesta: " + satisfaccion.sin_contestar,
            votes: satisfaccion.sin_contestar,
          },
          {
            id: 0,
            text: "😡 Muy insatisfecho: " + satisfaccion.enojado,
            votes: satisfaccion.enojado,
          },
          {
            id: 1,
            text: "🙁 Insatisfecho: " + satisfaccion.triste,
            votes: satisfaccion.triste,
          },
          {
            id: 2,
            text: "😐 Neutral: " + satisfaccion.neutro,
            votes: satisfaccion.neutro,
          },
          {
            id: 3,
            text: "😀 Satisfecho: " + satisfaccion.satisfecho,
            votes: satisfaccion.satisfecho,
          },
          {
            id: 4,
            text: "😊 Muy satisfecho: " + satisfaccion.feliz,
            votes: satisfaccion.feliz,
          },
        ]);
        setdata08([
          {
            id: -1,
            text: "Sin contestar encuesta: " + satisfaccion.sin_contestar_mes,
            votes: satisfaccion.sin_contestar_mes,
          },
          {
            id: 0,
            text: "😡 Muy insatisfecho: " + satisfaccion.enojado_mes,
            votes: satisfaccion.enojado_mes,
          },
          {
            id: 1,
            text: "🙁 Insatisfecho: " + satisfaccion.triste_mes,
            votes: satisfaccion.triste_mes,
          },
          {
            id: 2,
            text: "😐 Neutral: " + satisfaccion.neutro_mes,
            votes: satisfaccion.neutro_mes,
          },
          {
            id: 3,
            text: "😀 Satisfecho: " + satisfaccion.satisfecho_mes,
            votes: satisfaccion.satisfecho_mes,
          },
          {
            id: 4,
            text: "😊 Muy satisfecho: " + satisfaccion.feliz_mes,
            votes: satisfaccion.feliz_mes,
          },
        ]);
      });
    }
  }, [id]);

  const getclientesEstadosGet = async (tipo: any) => {
    Dashboard.clientesEstadosGet(tipo).then((res) => {
      const estados = res.estados;
      const data = Object.keys(estados).map((key) => {
        return {
          label: key,
          value: estados[key].total,
        };
      });
      setdata06(data);
    });
  };

  const parsearNumero = (numero: number) => {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const parsearNumeroInMilion = (numero: number) => {
    return (
      (numero / 1000000)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " M"
    );
  };

  const sacarPorcentaje = (numero: number, tope: number) => {
    const dato = (numero * 100) / tope;
    return Math.round(dato * 100) / 100 > 100
      ? 100
      : Math.round(dato * 100) / 100;
  };

  const newTheme = createTheme({
    palette: { mode: "light" },
    zIndex: {
      modal: 1,
    },
  });

  const getProductosZona = (zona: any) => {
    Dashboard.productosZonasGet(zona?.zonas_id).then((res) => {
      const productos_zona = res.productos_zona;
      createArrayListadoProductosNew(productos_zona);
      setTitle_03(zona?.zonas_id);
    });
  };

  const createArrayListadoProductosNew = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns3(columns);
    const datas = response;
    const newData = datas.map((res: any, index: any) => {
      return {
        _id: (index + 1).toString(),
        modelo: res.modelo,
        cantidad: res.cantidad.toString(),
      };
    });

    setdata3(newData);
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte" + fileExtension);
  };

  const parseMil = (num: number) => {
    // parsear numero a miles con K
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    }
    return num;
  };

  const obtenerMes = () => {
    const fecha = new Date();
    const mes = fecha.getMonth();
    const meses = [
      "ENERO",
      "FEBRERO",
      "MARZO",
      "ABRIL",
      "MAYO",
      "JUNIO",
      "JULIO",
      "AGOSTO",
      "SEPTIEMBRE",
      "OCTUBRE",
      "NOVIEMBRE",
      "DICIEMBRE",
    ];
    return meses[mes];
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        {/* Dos box */}
        <Flex bg="#fff" gap={4} p={4} borderRadius="md">
          <Box w="100%">
            {rol === "Master" && (
              <>
                <Box w="100%" paddingInline={5}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Ventas por mes{" "}
                    {sacarPorcentaje(data?.venta_mes ?? 0, data?.tope_mes ?? 0)}
                    %
                  </Heading>
                  <Flex gap={2} mt={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.venta_mes ?? 0,
                        data?.tope_mes ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.tope_mes ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" paddingInline={5} mt={7}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Comparativo del mes vs año pasado{" "}
                    {sacarPorcentaje(
                      data?.venta_mes ?? 0,
                      data?.["tope_año"] ?? 0
                    )}
                    %
                  </Heading>
                  <Flex gap={2} mt={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.venta_mes ?? 0,
                        data?.["tope_año"] ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.["tope_año"] ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" paddingInline={5} mt={7}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Acumulado del año{" "}
                    {sacarPorcentaje(data?.total_ano ?? 0, data?.tope_mes ?? 0)}
                    %
                  </Heading>
                  <Flex gap={2} mt={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.total_ano ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.total_ano ?? 0,
                        data?.["tope_año"] ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.["tope_año"] ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" paddingInline={5} mt={7}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Implementos vendidos{" "}
                  </Heading>
                  <Flex gap={2} mt={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {newData?.implementos ?? 0}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        newData?.implementos ?? 0,
                        500 ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {500}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" paddingInline={5} mt={7}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Productos vendidos{" "}
                  </Heading>
                  <Flex gap={2} mt={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {newData?.productos ?? 0}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(newData?.productos ?? 0, 500 ?? 0)}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {500}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" mt={5} display="flex" justifyContent="center">
                  <Box w="40%" padding={5}>
                    <Top getProductosZona={getProductosZona} />
                  </Box>

                  <Box w="59%" padding={5}>
                    <Heading as="h3" size="md" textAlign="center">
                      TICKETS DE SEGUIMIENTO
                    </Heading>

                    <ThemeProvider theme={newTheme}>
                      {data03.length > 0 && (
                        <Box width="100%" display={"flex"} mt={5}>
                          <PieChart02
                            series={[
                              {
                                data: data03,
                                highlightScope: {
                                  faded: "global",
                                  highlighted: "item",
                                },
                                faded: {
                                  innerRadius: 30,
                                  additionalRadius: -30,
                                  color: "gray",
                                },
                              },
                            ]}
                            onItemClick={(data: any, params: any) => {
                              const obtner = data03[params.dataIndex].datos;
                              const informacion = obtner.map((item: any) => {
                                return {
                                  label: item.label,
                                  value: item.value?.total,
                                };
                              });
                              setTitle_01(
                                data03[params.dataIndex].label.toUpperCase()
                              );
                              setdata05(informacion);
                            }}
                            title="Tickets de seguimiento"
                            margin={{
                              top: 0,
                              right: 20,
                              bottom: 0,
                              left: -150,
                            }}
                            height={200}
                          />
                        </Box>
                      )}
                    </ThemeProvider>
                    {data05.length > 0 && (
                      <Heading as="h3" size="md" textAlign="center" mt={3}>
                        COMPLEJIDAD {title_01}{" "}
                        <button
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => setdata05([])}
                        >
                          Cerrar
                        </button>
                      </Heading>
                    )}
                    <ThemeProvider theme={newTheme}>
                      {data05.length > 0 && (
                        <Box width="100%" display={"flex"} mt={5}>
                          <PieChart02
                            series={[
                              {
                                data: data05,
                                highlightScope: {
                                  faded: "global",
                                  highlighted: "item",
                                },
                                faded: {
                                  innerRadius: 30,
                                  additionalRadius: -30,
                                  color: "gray",
                                },
                              },
                            ]}
                            title="Tickets de seguimiento"
                            margin={{
                              top: 0,
                              right: 20,
                              bottom: 0,
                              left: -150,
                            }}
                            height={200}
                            colors={[
                              "#ffd1dc",
                              "#87ceeb",
                              "#98fb98",
                              "#ffffe0",
                              "#e6e6fa",
                              "#ffdab9",
                            ]}
                          />
                        </Box>
                      )}
                    </ThemeProvider>

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      marginTop={data05.length == 0 ? "220px" : "30px"}
                    >
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text={title_03}
                        textTransform={"uppercase"}
                      />
                    </Box>
                    <Box overflowX="auto" width={"100%"}>
                      {data3.length > 0 && (
                        <DataTable
                          columns={columns3}
                          data={data3}
                          edit=""
                          onOpenUpdate={() => {}}
                          setArray={[]}
                          ExportExcel={ExportExcel}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {rol === "Servicio Tecnico" && (
              <>
                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md">
                  <Heading as="h3" size="md" textAlign="center">
                    TICKETS NORMAL
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {data?.prioridad?.[0]?.total ?? 0}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>

                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md">
                  <Heading as="h3" size="md" textAlign="center">
                    TICKETS URGENTE
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {data?.prioridad?.[1]?.total ?? 0}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>
                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    TICKETS CRITICO
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {data?.prioridad?.[2]?.total ?? 0}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>
              </>
            )}

            {rol === "Contacto Stihl" && (
              <>
                <Box w="100%" padding={5}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Ventas por mes{" "}
                    {sacarPorcentaje(data?.venta_mes ?? 0, data?.tope_mes ?? 0)}
                    %
                  </Heading>
                  <Flex gap={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.venta_mes ?? 0,
                        data?.tope_mes ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.tope_mes ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" padding={5}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Ventas por año{" "}
                    {sacarPorcentaje(data?.total_ano ?? 0, data?.tope_mes ?? 0)}
                    %
                  </Heading>
                  <Flex gap={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.total_ano ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.total_ano ?? 0,
                        data?.["tope_año"] ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.["tope_año"] ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md">
                  <Heading as="h3" size="md" textAlign="center">
                    POSIBLES CLIENTES
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {parsearNumero(data?.posibles_clientes ?? 0)}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>
                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    TOTAL VENTAS
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {parsearNumeroInMilion(data?.total_ano ?? 0)}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>

                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    VENTA MES ACTUAL
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>
                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    TICKETS DE SEGUIMIENTO
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {data?.ticket ?? 0}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>
              </>
            )}

            {rol === "Ventas" && (
              <>
                <Box w="100%" padding={5}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Ventas por mes{" "}
                    {sacarPorcentaje(data?.venta_mes ?? 0, data?.tope_mes ?? 0)}
                    %
                  </Heading>
                  <Flex gap={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.venta_mes ?? 0,
                        data?.tope_mes ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.tope_mes ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="100%" padding={5}>
                  <Heading as="h3" size="sm" textAlign="center">
                    Ventas por año{" "}
                    {sacarPorcentaje(data?.total_ano ?? 0, data?.tope_mes ?? 0)}
                    %
                  </Heading>
                  <Flex gap={2}>
                    <Heading as="h5" size="sm" textAlign="center" width={63}>
                      {parsearNumeroInMilion(data?.total_ano ?? 0)}
                    </Heading>
                    <Progress
                      width={"80%"}
                      colorScheme="orange"
                      height="20px"
                      value={sacarPorcentaje(
                        data?.total_ano ?? 0,
                        data?.["tope_año"] ?? 0
                      )}
                    />
                    <Heading as="h5" size="sm" textAlign="center">
                      {parsearNumeroInMilion(data?.["tope_año"] ?? 0)}
                    </Heading>
                  </Flex>
                </Box>

                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    TOTAL VENTAS
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {parsearNumeroInMilion(data?.total_ano ?? 0)}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>

                <Box w="49%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    VENTA MES ACTUAL
                  </Heading>

                  <Box width="100%" height="100%" mt={5}>
                    <Fade in={isOpen}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange">
                          <Heading as="h3" size="2xl" textAlign="center">
                            {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                          </Heading>
                        </div>
                      </Flex>
                    </Fade>
                  </Box>
                </Box>

                <Box w="100%" bg="#eeeeee" p={4} borderRadius="md" mt={2}>
                  <Heading as="h3" size="md" textAlign="center">
                    TOP 10 PRODUCTOS MÁS VENDIDOS
                  </Heading>

                  <TableContainer
                    sx={{
                      marginTop: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Modelo</Th>
                          <Th>Cantidad</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.top?.map((res: any) => (
                          <Tr>
                            <Td>{res.modelo}</Td>
                            <Td>{res.cantidad}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}
          </Box>

          <Box w="70%" bg="#eeeeee" p={4} borderRadius="md">
            <Heading as="h3" size="md" textAlign="center" mt={3}>
              FUENTES DE TICKETS
            </Heading>

            <ThemeProvider theme={newTheme}>
              {data02.length > 0 && (
                <Box width="100%" display={"flex"} mt={5}>
                  <PieChart02
                    series={[
                      {
                        data: data02,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    title="Fuentes de tickets"
                    margin={{ top: 0, right: 20, bottom: 0, left: -150 }}
                    height={200}
                  />
                </Box>
              )}
            </ThemeProvider>

            <Heading as="h3" size="md" textAlign="center" mt={10}>
              TIPO DE CLIENTES {obtenerMes()}
            </Heading>

            <ThemeProvider theme={newTheme}>
              {data04.length > 0 && (
                <Box width="100%" display={"flex"} mt={5}>
                  <PieChart02
                    series={[
                      {
                        data: data04,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                        arcLabel: (item) => `${parseMil(item.value)}`,
                      },
                    ]}
                    onItemClick={(data: any, params: any) => {
                      const array: any = {
                        Clientes: "clientes",
                        "Posibles clientes": "posiblesClientes",
                      };
                      const label: any = data04[params.dataIndex].label;
                      getclientesEstadosGet(array[label]);
                      setTitle_02(label);
                      setModal(true);
                    }}
                    title="Tipo de clientes"
                    margin={{ top: 0, right: 20, bottom: 0, left: -150 }}
                    height={200}
                  />
                </Box>
              )}
            </ThemeProvider>

            <div
              style={{
                marginTop: "20px",
              }}
            ></div>
            <Tabs>
              <TabList>
                <Tab>Mensual</Tab>
                <Tab>Acumulado</Tab>
              </TabList>

              <TabPanel>
                {data08 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: 20,
                      marginTop: "20px",
                    }}
                  >
                    {data08.map((item: any) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "0 10px",
                          backgroundColor: "white",
                          padding: "10px",
                          borderRadius: "10px",
                          boxShadow: "0 0 5px #000000",
                        }}
                        role="button"
                      >
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {item.text}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {data07 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: 20,
                      marginTop: "20px",
                    }}
                  >
                    {data07.map((item: any) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "0 10px",
                          backgroundColor: "white",
                          padding: "10px",
                          borderRadius: "10px",
                          boxShadow: "0 0 5px #000000",
                        }}
                      >
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {item.text}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </TabPanel>
            </Tabs>

            <ModalV
              isOpen={data06.length > 0}
              onClose={() => setdata06([])}
              data06={data06}
              setdata06={setdata06}
              title_02={title_02}
              newTheme={newTheme}
            />

            <ModalEncuestas
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              arrayTipos={[]}
              setarrayTipos={() => {}}
              newTheme={newTheme}
            />
          </Box>
        </Flex>
      </Sidebar>
    </div>
  );
}

const ModalV = ({
  isOpen,
  onClose,
  data06,
  setdata06,
  title_02,
  newTheme,
}: any) => {
  return (
    <ThemeProvider theme={newTheme}>
      <Modal open={isOpen} onClose={onClose}>
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            borderRadius: "10px",
            width: "80%",
            margin: "auto",
            height: "95%",
          }}
        >
          {data06.length > 0 && (
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "20px",
              }}
            >
              {title_02}
              {"   "}
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setdata06([])}
              >
                Cerrar
              </button>
            </p>
          )}
          {data06.length > 0 && (
            <Box width="100%" display={"flex"} mt={5}>
              <PieChart02
                series={[
                  {
                    data: data06,
                    highlightScope: {
                      faded: "global",
                      highlighted: "item",
                    },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    arcLabel: (item) => `${item.label} (${item.value})`,
                    arcLabelRadius: 250,
                    arcLabelMinAngle: 10,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "black",
                    fontWeight: "bold",
                  },
                }}
                margin={{ top: 0, right: 20, bottom: 0, left: -300 }}
                height={600}
                colors={[
                  "#ffd1dc",
                  "#87ceeb",
                  "#98fb98",
                  "#ffffe0",
                  "#e6e6fa",
                  "#ffdab9",
                ]}
              />
            </Box>
          )}
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const ModalEncuestas = ({
  isOpen,
  onClose,
  arrayTipos,
  setarrayTipos,
  newTheme,
}: any) => {
  const preguntaCentroServicio = [
    {
      type: 1,
      pregunta: "¿Solucionamos todas las dudas técnicas de tu solicitud?",
    },
    {
      type: 2,
      pregunta:
        "¿Cómo consideras que fue la atención de nuestro Centro de servicio autorizado más cercano?",
    },
    {
      type: 3,
      pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
    },
    {
      type: 1,
      pregunta:
        "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
    },
    {
      type: 4,
      pregunta:
        "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
    },
  ];

  const preguntaRefacciones = [
    {
      type: 2,
      pregunta:
        "¿Cómo consideras que fue la atención brindada para adquirir tu equipo/refacción/accesorio STIHL?",
    },
    {
      type: 1,
      pregunta:
        "¿Lograste adquirir tu equipo/refacción/accesorio STIHL con un distribuidor autorizado?",
    },
    {
      type: 3,
      pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
    },
    {
      type: 1,
      pregunta:
        "¿Estás interesado en recibir información relacinada al equipo/refacción/accesorio STIHL que adquiriste?",
    },
    {
      type: 4,
      pregunta:
        "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
    },
  ];

  const preguntaReportes = [
    {
      type: 3,
      pregunta: "¿Qué tan satisfecho estás con el producto que adquiriste?",
    },
    {
      type: 3,
      pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
    },
    {
      type: 2,
      pregunta: "¿Cómo consideras que fue la atención para resolver tu duda?",
    },
    {
      type: 1,
      pregunta:
        "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
    },
    {
      type: 4,
      pregunta:
        "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
    },
  ];

  const preguntas = (tipo: any, index_tipo: any) => {
    let array: any = [];
    if (tipo === "Centro de servicio autorizado más cercano") {
      array = preguntaCentroServicio;
    } else if (
      tipo === "Refacciones" ||
      tipo === "Cotización" ||
      tipo === "Venta"
    ) {
      array = preguntaRefacciones;
    } else if (tipo === "Reporte (Proceso de garantia)") {
      array = preguntaReportes;
    }
    return array.map((pregunta: any, index: any) => {
      if (pregunta.type === 1) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setarrayTipos({
                  ...arrayTipos,
                  [index_tipo]: {
                    ...arrayTipos[index_tipo],
                    ["pregunta_" + index]: e,
                  },
                });
              }}
              value={arrayTipos[index_tipo]["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Si</Radio>
                <Radio value="3">No</Radio>

                {arrayTipos[index_tipo]["pregunta_" + index] === "3" && (
                  <Input
                    placeholder="Observaciones"
                    value={
                      arrayTipos[index_tipo][
                        "pregunta_" + index + "_observaciones"
                      ] ?? ""
                    }
                    onChange={(e) =>
                      setarrayTipos({
                        ...arrayTipos,
                        [index_tipo]: {
                          ...arrayTipos[index_tipo],
                          ["pregunta_" + index + "_observaciones"]:
                            e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 2) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setarrayTipos({
                  ...arrayTipos,
                  [index_tipo]: {
                    ...arrayTipos[index_tipo],
                    ["pregunta_" + index]: e,
                  },
                });
              }}
              value={arrayTipos[index_tipo]["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Muy buena</Radio>
                <Radio value="3">Buena</Radio>
                <Radio value="2">Regular</Radio>
                <Radio value="1">Mala</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 3) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setarrayTipos({
                  ...arrayTipos,
                  [index_tipo]: {
                    ...arrayTipos[index_tipo],
                    ["pregunta_" + index]: e,
                  },
                });
              }}
              value={arrayTipos[index_tipo]["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Muy probable</Radio>
                <Radio value="3">Probable</Radio>
                <Radio value="2">Poco probable</Radio>
                <Radio value="1">Nada probable</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 4) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "50%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>
            <Textarea
              placeholder="Observaciones"
              value={arrayTipos[index_tipo]["pregunta_" + index] ?? ""}
              onChange={(e) =>
                setarrayTipos({
                  ...arrayTipos,
                  [index_tipo]: {
                    ...arrayTipos[index_tipo],
                    ["pregunta_" + index]: e.target.value,
                  },
                })
              }
            />
          </Flex>
        );
      }
    });
  };

  return (
    <ThemeProvider theme={newTheme}>
      <Modal open={isOpen} onClose={onClose}>
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            borderRadius: "10px",
            width: "80%",
            margin: "auto",
            height: "95%",
          }}
        >
          {arrayTipos.map((item: any, index: any) => {
            return preguntas(item.tipo, index);
          })}
        </div>
      </Modal>
    </ThemeProvider>
  );
};
